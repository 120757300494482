<template lang="pug">
  v-container(fluid)
    v-layout(wrap='')
      // Snack bar
      v-snackbar(v-model='snackbar.snackbar' :color='snackbar.color' :right="true" :timeout='snackbar.timeout' :top="true")
        | {{ snackbar.text }}
        v-btn(dark='' text='' @click='snackbar.snackbar = false')
          | Close
      // Cancel subscription
      v-dialog(v-model='cancelDialog' width="500px" persistent)
        v-card
          v-card-title
            | Cancel subscription
            v-spacer
              v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
          v-card-text.body-1.text-center
            | ¿Cancel Subscription?
          v-card-actions.text-center
            v-spacer
              v-btn(color='green darken-1', @click.native='cancelDialog = false') Cancel
              v-btn(color='red darken-1',  @click.native='cancelDialog = false; confirmCancelDialog = true') Yes, proceed
      // Confirm cancel
      v-dialog(persistent v-if="confirmCancelDialog" hide-overlay :value='confirmCancelDialog' max-width='700px' transition="dialog-bottom-transition")
        CancelSubscription(@close="confirmCancelDialog=false" :plan="customer.subscription" v-if='confirmCancelDialog' )
      // New card
      v-dialog(persistent :value='cardDialog' max-width='400px' transition="dialog-bottom-transition")
        newCard(@close="cardDialog=false" v-if='cardDialog' )
      // Content
      v-layout.plans
        v-col(cols="12" md="4")
          base-material-card(icon='mdi-earth' title='Selected Plan', style='width:100%')
            v-row
              v-col(cols='12' md='12').text-center
                v-btn(small @click="goBack").mx-2.hidden-xs-only
                  | Back to plans
                  v-icon mdi-arrow-left-bold
            PagesPlanCardReduced(:plan="plan")
        v-col(cols="12" md="8")
          template(v-if="customer")
            v-card(width='100%')
              v-flex(md12='',)
                v-row
                  v-col(md="4" sm="12" cols='12')
                    v-row.text-center
                      v-col(md="12")
                        p.card-title MEMBERSHIP
                        span.font-weight-light.grey--text(v-if="mainCard")
                          | Your payments will be made with the main card: {{mainCard.brand}} {{mainCard.number}}
                      v-col(cols="12" md="12")
                        v-btn(v-if="customer && !subscription" color="success" small @click="addPlan" :disabled="!mainCard" :loading="loading") Subscribe to {{plan.nickname}}
                      v-col(cols="12" md="12")
                        v-btn(v-if="subscription && subscription.plan.id !== plan.id" color="success" small :disabled="!mainCard" :loading="loadingChange" @click="updatePlan") Change to {{plan.nickname}}
                  v-col(md="8" sm="12" cols='12' style="text-align: center;")
                    v-row
                      v-col
                        p.card-title.font-weight-light Select Card
                      v-col
                        v-btn(color="success" small @click="cardDialog = true") New card
                    v-flex(style="padding: 0px;")
                        v-col(md="12" cols='12' style="padding: 0px 10px;")
                          v-data-table(:headers="headers" :items="cards" :items-per-page="5")
                            template(v-slot:item.actions="{ item }")
                              v-btn(:disabled="item.main" color="primary" text small @click.stop='setMainCard(item)') Main card
                template(v-if="subscription")
                  v-row
                    v-col(md="4" sm="12" cols='12' style="text-align: center;")
                      p.card-title ACTUAL PLAN
                      p.card-title(style="font-weight: bold; color: #999999;") {{subscription.plan.name}}
                      p.card-title(style="font-weight: bold; color: #999999;") {{subscription.status}}
                    v-col(md="8" sm="12" cols='12' style="text-align: center;")
                      v-row
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='Name', required='' v-model="subscription.plan.name"
                          style="padding: 5px;" disabled=true)
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='Cost $', required=''
                          style="padding: 5px;" disabled=true v-model="subscription.plan.amount")
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='Interval', required='' v-model="subscription.plan.interval"
                          style="padding: 5px;" disabled=true)
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='Creation Date', required='' :value="subscription.created | moment"
                          style="padding: 5px;" disabled=true)
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='Start Date', required='' :value="subscription.start | moment"
                          style="padding: 5px;" disabled=true)
                        v-col(md="4" sm="12" cols='12' style="text-align: center;")
                          v-text-field(label='End Date', required='' :value="subscription.end | moment"
                          style="padding: 5px;" disabled=true)
                    v-col(cols="12" md="12").text-right
                      span.text-no-wrap
                          a.ml-6.ml-sm-0(@click="cancelDialog = true")
                            | Cancel subscription
          template(v-if="!customer")
              v-row(no-gutters).mt-7
                v-col(cols="12" sm="9")
                  div(ref="card")
                v-col(cols="12" sm="3")
                  v-btn(color="success" @click="purchase" block :loading="loading") Subscribe {{plan.amount | moneyFormat}}
</template>

<script>
  /* eslint-disable */
  // Stripe element
  const stripe = Stripe('pk_live_Dfd8kq0KeASDVrcLQWoZazR000xAgaateT')
  // plugins
  import axios from 'axios'
  import loaders from '@/plugins/mixins/loaders'
  import moment from 'moment'
  import firebase from 'firebase'
  import * as vars from '@/vars.json';
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    mixins: [loaders],

    filters: {
      moment: function (date) {
        return moment(date * 1000).format('YYYY-MM-DD');
      },
      moneyFormat: function (value) {
        const entero = value.toString().slice(0, -2)
        const decimal = value.toString().slice(-2)
        return formatter.format(entero.concat('.', decimal))
      },
    },

    components: {
      PagesPlanCardReduced: () => import('./PlanCardReduced'),
      CancelSubscription: () => import('@/components/custom/dialogs/CancelSubscription.vue'),
      newCard: () => import('@/components/custom/dialogs/newCard.vue')
    },

    data () {
      return {
        card: '',
        loading: false,
        loadingChange: false,
        headers: [
          { text: 'Brand', value: 'brand'},
          { text: 'Numbers', value: 'number' },
          { text: 'Month', value: 'exp_month' },
          { text: 'Year', value: 'exp_year' },
          { text: 'Type', value: 'type' },
          { text: 'Actions', value: 'actions' },
        ],
        snackbar: {
          color: 'red',
          snackbar: false,
          text: '',
          timeout: 2000,
        },
        // Dialogs
        cancelDialog: false,
        confirmCancelDialog: false,
        cardDialog: false,
      }
    },

    computed: {
      plan () {
        return this.$store.getters['subscriptions/getCurrentPlan']
      },

      customer () {
        return this.$store.getters['subscriptions/getStripe']
      },

      mainCard () {
        return this.$store.getters['subscriptions/getMainCard']
      },

      cards () {
        return Object.values(this.$store.getters['subscriptions/getStripe'].cards)
      },

      subscription () {
        return this.$store.getters['subscriptions/getStripe'].subscription
      },

      payments () {
        return this.$store.getters['subscriptions/getPayments']
      },

    },

    mounted () {
      const vm = this
      vm.loadStripe()
      if(vm.customer){
        vm.getcustomer(vm.customer.customer)
      } else {
        let elements = stripe.elements()
        vm.card = elements.create('card')
        vm.card.mount(this.$refs.card)
      }
    },

    methods: {

      purchase () {
        const vm = this
        vm.loading = true
        stripe.createPaymentMethod({
          type: 'card',
          card: vm.card,
          billing_details: {
            email: vm.$store.state.users.user.email,
          },
        }).then(function (result) {
          if (result.error) {
            vm.loading = false
            vm.snackbar.text = result.error.message
            vm.snackbar.snackbar = true
          } else {
            vm.createCustomer(result.paymentMethod.id, result)
          }
        })
      },

      createCustomer (token, result) {
        const vm = this
        const customer = {
          token: token,
          email: vm.$store.state.users.user.email,
          uid: vm.$store.state.users.user.uid,
          plan: vm.plan.id,
          metadata: vm.plan.metadata,
          card: {
            ...result.paymentMethod.card
          }
        }
        axios.post(vars.urlProd+'/createCustomer', customer).then(res => {
          vm.loading = false
          vm.card.destroy()
        }).catch(e => {
          vm.loading = false
          if (e.response.data) {
            vm.snackbar.text = e.response.data.err.raw.message
            vm.snackbar.snackbar = true
          }
        })
      },

      getcustomer (token) {
        // console.log(token)
        const tokenCustomer = {
          token: token
        }
        axios.post(vars.urlProd+'/getCustomer', tokenCustomer).then(res => {
          // console.log(res)
        }).catch(e => {
          console.log(e)
        })
      },

      goBack () {
        this.$router.push('Plans')
      },

      updatePlan () {
        const vm = this
        vm.loadingChange = true
        const update = {
          uid: vm.$store.state.users.user.uid,
          plan: vm.plan.id,
          method: vm.mainCard.token,
          subscription: vm.subscription.id,
          metadata: vm.plan.metadata,
        }
        // console.log(update)
        axios.post(vars.urlProd+'/updateSubcription', update).then(res => {
          vm.loadingChange = false
        }).catch(e => {
          vm.loadingChange = false
          if (e.response.data) {
            vm.snackbar.color = 'red'
            vm.snackbar.text = e.response.data.raw.message
            vm.snackbar.snackbar = true
          }
        })
      },

      newCard () {
        const vm = this
        vm.addCard = !vm.addCard
        let elements = stripe.elements()
        vm.card = elements.create('card')
        vm.card.mount(this.$refs.card)
      },

      addPlan () {
        const vm = this
        vm.loading = true
        const customer = {
          customer: vm.customer.customer,
          uid: vm.$store.state.users.user.uid,
          plan: vm.plan.id,
          metadata: vm.plan.metadata,
          token: vm.mainCard.token
        }
        // console.log(customer)
        axios.post(vars.urlProd+'/addSubcription', customer).then(res => {
          vm.loading = false
        }).catch(e => {
          vm.loading = false
        })
      },

      setMainCard (card) {
        const vm = this
        let updateCustomer = {
          uid: vm.$store.state.users.user.uid,
          cardId: card.id,
          cardToken: card.token,
          customer: vm.customer.customer,
        }
        axios.post(vars.urlProd+'/updateCustomer', updateCustomer).then(res => {
          vm.snackbar.color = 'success'
          vm.snackbar.text = 'Main card set'
          vm.snackbar.snackbar = true
        }).catch(e => {
          vm.loading = false
          if (e.response.data) {
            vm.snackbar.color = 'red'
            vm.snackbar.text = e.response.data.raw.message
            vm.snackbar.snackbar = true
          }
        })
      }

    },

    beforeDestroy () {
      const vm = this
      vm.card.destroy()
    }

  }
</script>

<style lang="scss">
  .plans{
    display: flex;
    flex-direction: row;
    padding-bottom: 75px;
    flex-wrap: wrap;
  }
  .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

